<template>
        <section class="mb-10">
            <v-layout justify-space-around justify-center>
                <v-flex xs10 sm10 offset-sm1 class="mt-10">
                        <v-card flat tile color="" width="92%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center pa-0">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Powering your growth</h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading solutions-heading">Powering <br /> your growth</h3><br />

                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row no-gutters>
                                <v-col lg="6" md="6" sm="12" xs="12" justify="center" align="center">
                                        <v-img :src="`${s3Path}story.png`"></v-img>
                                </v-col>
                                <v-col lg="6" md="6" sm="12" xs="12">
                                    <v-card flat tile color="" width="90%" class="transparent main-content-products">
                                        <v-card-text v-html="sectionData">
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';
export default {
    name: 'Description',
    mixins: [colorMixin, coreMixin],
    props: ['section'],

    computed: {
        sectionData () {
            let text = '';

            switch (this.section) {
                case 'international':
                    text = `
                    <p><br /></p>
                    <p>An international company is able, by integrating its systems to Zamupay, to send money to mobile money and bank accounts of its recipients in different markets within seconds.
                    </p>
                    <p> E-KYC is also available for verification of the recipients before sending out the funds. </p>
                    <p>Through our user-friendly interface, it will be easy to track and reconcile all your transactions.
                    </p>`;

                     break;
                 case 'smes':
                    text = `<p>Ditch the headache and risks associated with handling cash. </p>
                    <p>On Zamupay, merchants and other trading organizations can receive payments from their customers (using mobile money, cards, banks, online, etc.) and also make business payments (suppliers, employee payments, bills payments etc.), in minutes,  allowing you to focus on what’s important; growth.
                    </p>
                    <p>
                    Zamupay will help in collection and payments from one central point. </p>
                    <p>Access to credit and insurance products, business utility bill payments with reminders.

                    </p>
                    `;
                     break;
                 case 'corporate':
                    text = `<p>if you are looking to automate payments and integrate applications with banks and mobile money providers for making and receiving payments, then Zamupay is for you. : Use Zamupay for your payments, financial analytics, reporting, reconciliation, tracking and auditing of your transactions. </p>
                    <p>
                    Institutions such as SACCOS (credit unions), Microfinance Iinstitutions, Fintechs and even Commercial Banks making/receiving many payments over a given period, can integrate their applications with Zamupay for a secure, easy and fast processing of such transactions via API. Pre-approved manual initiation of payments via a web portal is also supported.

                    </p>
                    <p>
                    Plug in to Zamupay and take advantage of our easy to use API to launch scalable, innovative initiatives that can be easily personalized to your brand, in an agile, efficient and efficient way.
                    </p>
                    
                    `;
                    break;
                 case 'fintech': 
                    text = `
                    <p><br /></p>
                  
                    <p>
                    Through Zamupay, scale up your portfolio and reach new clients by accessing new payment channels and markets using a single integration. </p>
                    <p>Plug in to Zamupay and take advantage of our easy to use API to launch scalable, innovative initiatives that can be easily personalized to your brand, in an agile efficient and efficient way.
                    </p>
                    
                    `;
                    break;
                 case 'financial': 
                   text = `<p>
                    Zamupay aids in payment aggregation around one secure and stable solution, enabling the institution to focus on its core business and/or innovation. </p>
                    <p>SACCOs and other microfinance institutions can aggregate all payments with Zamupay; receive loan repayments and deposits as well as send money to your customers via the secure and reliable platform. </p>
                    <p>Integrate your systems to Zamupay and unlock your potential.

                    </p>
                    <p>
                      Banks can support both new and old transaction types on the customer-facing channels using Zamupay’s API. 
                    </p>
                    `
                    break;

                     case 'cross-border': 
                   text = `<p>
                    Zamupay provides real-time processing of transactions with a 99 percent uptime. </p>
                    <p>It supports multiple transaction types and markets with a single integration. </p>
                    <p>It is also prompt and offers 24/7 support. </p>
                    <p>Zamupay for your Mobile Payments, Bank Transfers, Card P2P and Cross-Border International Remittances.

                    </p>
                    
                    `
                    break;
                  case 'saccos': 
                   text = `<p>
                    With most SACCOs moving from pure BOSA (Back Office Service Activity) to what is popularly known as FOSA (Front Office Service Activity) operations, many of them are now able to receive deposits from their members and have them access the same on demand. In essence, many SACCOs (at least 172 in Kenya, currently) are banks in all but name, offering the full portfolio of banking services. </p>
                    <p>
                    In such a context, the need for secure, efficient and convenient transactions by members is critical. This can be achieved through the installation of robust and integrated technological platforms that can serve members seamlessly, 24/7, while protecting the SACCOs from the risks that come with their role as custodians of members’ funds.
                    </p>
                    
                    `
                    break;
                
                
            
                default:
                    break;
            }
            return text;

        },

    },

}
</script>

<style>
.solutions-heading {
    font-size: 40px !important;
}

</style>